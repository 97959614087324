import React, { useEffect,useState } from 'react';
import ReactDOM from 'react-dom';
import './index.scss';

import { BrowserRouter, Switch, Route,Redirect } from 'react-router-dom';
import * as serviceWorker from './serviceWorker';
import "@fortawesome/fontawesome-free/css/all.min.css";

// ** Import custom components for redux **
import { Provider } from 'react-redux';
import store from './store';
import App from "./components/app";
// Import custom Components 
import Dashboard from './components/dashboard/default';

// users
import UserEdit from './components/users/userEdit';

//extensions
import Extensions from './components/app_management/extensions';

//domains
import Domains from './components/domains/domains'

//users
import Users from './components/users/Summary'
import UserDetails from './components/users/Details'

//Subscribers
import Subscribers from './components/subscribers/Summary'
import SubscriberDetails from './components/subscribers/Details'
import WhiteLabel from './components/subscribers/WhiteLabel'
//Netsapiens
import Netsapiens from './components/netsapiens/Summary'
import NetsapienDetails from './components/netsapiens/Details'

//Client Profiles
import ClientProfiles from './components/clientProfiles/Summary'
import ClientProfileDetails from './components/clientProfiles/Details'

//logs
import Logs from './components/sysLogs/logs'


// pages 
import Login from './pages/login';
import LoginWithBgImg from './pages/loginWithBgImg';
import LoginWithVideo from './pages/loginWithVideo';
import Signup from './pages/signup';
import SignupWithImg from './pages/signupWithImg';
import SignupWithVideo from './pages/signupWithVideo';
import UnlockUser from './pages/unlockUser';
import ForgetPwd from './pages/forgetPwd';
import ResetPwd from './pages/resetPwd';
import ComingSoon from './pages/comingsoon';
import ComingSoonImg from './pages/comingsoonImg';
import ComingSoonVideo from './pages/comingsoonVideo';
import Maintenance from './pages/maintenance';
import Error400 from './pages/errors/error400';
import Error401 from './pages/errors/error401';
import Error403 from './pages/errors/error403';
import Error404 from './pages/errors/error404';
import Error500 from './pages/errors/error500';
import Error503 from './pages/errors/error503';

import VoiceMessages from './pages/settings/VoiceMessage';
import VoiceMailSettings from './pages/settings/VoicemailSettings'; 
import QRGenerator from './pages/settings/QrGenerator'; 
import Engage from './pages/settings/Engage'; 

// Import Applications Components

import Signin from './auth/signin';
import AppSignin from './auth/appSignin';
import ResetPassword from './auth/resetPassword'

//config data
import configDB from './data/customizer/config'

import Index from './pages/settings/Index';

const Root = () => {

    const abortController = new AbortController();
    const [currentUser, setCurrentUser] = useState(false);
    const [authenticated,setAuthenticated] = useState(false)

    useEffect(() => {

        console.log(process.env.PUBLIC_URL);

        const color = localStorage.getItem('color')
        const layout = localStorage.getItem('layout_version') || configDB.data.color.layout_version
        setAuthenticated(JSON.parse(localStorage.getItem("authenticated")))
        document.body.classList.add(layout);
        console.ignoredYellowBox = ['Warning: Each', 'Warning: Failed'];
        console.disableYellowBox = true;
        document.getElementById("color").setAttribute("href", `${process.env.PUBLIC_URL}/assets/css/${color}.css`);

        return function cleanup() {
            abortController.abort();
        }
        
    // eslint-disable-next-line
    }, []);

    return (
        <div className="App">
            
            <Provider store={store}>
                <BrowserRouter basename={`/`}>
                        <Switch>
                            <Route path={`${process.env.PUBLIC_URL}/login`} component={Signin} />
                            <Route path={`${process.env.PUBLIC_URL}/appSignin`} component={AppSignin} />
                            <Route path={`${process.env.PUBLIC_URL}/resetPassword`} component={ResetPassword} />
                            <Route path={`${process.env.PUBLIC_URL}/pages/login`} component={Login} />
                            <Route path={`${process.env.PUBLIC_URL}/pages/loginWithBgImg`} component={LoginWithBgImg} />
                            <Route path={`${process.env.PUBLIC_URL}/pages/loginWithVideo`} component={LoginWithVideo} />
                            <Route path={`${process.env.PUBLIC_URL}/pages/signup`} component={Signup} />
                            <Route path={`${process.env.PUBLIC_URL}/pages/signupWithImg`} component={SignupWithImg} />
                            <Route path={`${process.env.PUBLIC_URL}/pages/signupWithVideo`} component={SignupWithVideo} />
                            <Route path={`${process.env.PUBLIC_URL}/pages/unlockUser`} component={UnlockUser} />
                            <Route path={`${process.env.PUBLIC_URL}/pages/forgetPwd`} component={ForgetPwd} />
                            <Route path={`${process.env.PUBLIC_URL}/pages/resetPwd`} component={ResetPwd} />
                            <Route path={`${process.env.PUBLIC_URL}/pages/comingsoon`} component={ComingSoon} />
                            <Route path={`${process.env.PUBLIC_URL}/pages/comingsoonImg`} component={ComingSoonImg} />
                            <Route path={`${process.env.PUBLIC_URL}/pages/comingsoonVideo`} component={ComingSoonVideo} />
                            <Route path={`${process.env.PUBLIC_URL}/pages/maintenance`} component={Maintenance} />
                            <Route path={`${process.env.PUBLIC_URL}/pages/errors/error400`} component={Error400} />
                            <Route path={`${process.env.PUBLIC_URL}/pages/errors/error401`} component={Error401} />
                            <Route path={`${process.env.PUBLIC_URL}/pages/errors/error403`} component={Error403} />
                            <Route path={`${process.env.PUBLIC_URL}/pages/errors/error404`} component={Error404} />
                            <Route path={`${process.env.PUBLIC_URL}/pages/errors/error500`} component={Error500} />
                            <Route path={`${process.env.PUBLIC_URL}/pages/errors/error503`} component={Error503} />
                            
                            {/* Custom Web Pages */}
                            <Route path={`${process.env.PUBLIC_URL}/custom_tab/index`} render={(props) => <Index {...props} />} />
                            <Route path={`${process.env.PUBLIC_URL}/custom_tab/voice_messages`} render={(props) => <VoiceMessages {...props}/>}/>
                            <Route path={`${process.env.PUBLIC_URL}/custom_tab/vmail_settings`} render={(props) => <VoiceMailSettings {...props}/>}/>
                            <Route path={`${process.env.PUBLIC_URL}/custom_tab/engage`} render={(props) => <Engage {...props}/>}/>
                            <Route path={`${process.env.PUBLIC_URL}/getQR`} render={(props) => <QRGenerator {...props}/>}/>

                            {localStorage.getItem('token') ?
                            
                                <App>
                                    {/* dashboard menu */}
                                    <Route exact path={`${process.env.PUBLIC_URL}/`} render={() => {
                                        return (<Redirect to={`${process.env.PUBLIC_URL}/dashboard`} />)
                                    }} />
                                    <Route path={`${process.env.PUBLIC_URL}/dashboard`} component={Dashboard} />

                                    {/* Users */}
                                    <Route path={`${process.env.PUBLIC_URL}/userEdit`} component={UserEdit} />

                                    <Route path={`${process.env.PUBLIC_URL}/app_management`} component={Extensions} />
                                    
                                    <Route path={`${process.env.PUBLIC_URL}/messaging`} component={Domains} />

                                    <Route exact path={`${process.env.PUBLIC_URL}/users`} component={Users}/>
                                    <Route path={`${process.env.PUBLIC_URL}/users/details`} render={(props) => <UserDetails {...props}/>}/>

                                    <Route exact path={`${process.env.PUBLIC_URL}/subscribers`} component={Subscribers}/>
                                    <Route path={`${process.env.PUBLIC_URL}/subscribers/details`} render={(props) => <SubscriberDetails {...props}/>}/>
                                    <Route path={`${process.env.PUBLIC_URL}/subscribers/white_label`} render={(props) => <WhiteLabel {...props} />} />

                                    <Route exact path={`${process.env.PUBLIC_URL}/netsapiens`} component={Netsapiens}/>
                                    <Route path={`${process.env.PUBLIC_URL}/netsapiens/details`} render={(props) => <NetsapienDetails {...props}/>}/>

                                    <Route exact path={`${process.env.PUBLIC_URL}/client_profiles`} component={ClientProfiles} />
                                    <Route path={`${process.env.PUBLIC_URL}/client_profiles/details`} render={(props) => <ClientProfileDetails {...props} />} />

                                    <Route path={`${process.env.PUBLIC_URL}/logs`} component={Logs} />

                                </App>
                            :
                            <App>
                                <Redirect to={`${process.env.PUBLIC_URL}/login`} />
                                <Route path={`${process.env.PUBLIC_URL}/resetPassword`} component={ResetPassword} />
                                
                            </App>
                            } 
                        </Switch>
                </BrowserRouter>
            </Provider>
            
        </div>
    );
}

ReactDOM.render(<Root />, document.getElementById('root'));

serviceWorker.unregister();